<template>
  <v-app>
<div>
<BreadcrumbComponent :items=items></BreadcrumbComponent>
<PreloaderComponent :enable=enable></PreloaderComponent>

<v-container fluid class="settingscontainer" v-if=enable>
  <v-card
  elevation="2" class="text-right"
>
<v-card class="filterrow">
  <v-row style="padding-left:20px">
    <v-col
      cols="4"
      sm="4"
      md="4"
    >
    <Datepicker format="yyyy-MM-dd" v-model=state.from class="dateinput"></Datepicker></v-col>
    <v-col
      cols="4"
      sm="4"
      md="4"
    >
    <Datepicker format="yyyy-MM-dd" v-model=state.to class="dateinput"></Datepicker></v-col>
    <v-col cols="1" sm="1" md="1">
      <v-btn
      style="margin-top:-1px"
      tile
      color="success"
      v-on:click=changedate
    >
      Filtrer
    </v-btn>
    </v-col>
  </v-row>
</v-card>
  <v-card>
  <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Rechercher"
        single-line
        hide-details
        class="tablesearch"
      ></v-text-field>
    </v-card-title>
  <v-data-table
    :headers="headers"
    :items="rows"
    :items-per-page="10"
    class="elevation-1"
    :search="search"
  >
    <template v-slot:item.status="{ item }">
      <v-chip
        :class="getStatusColor(item.status)"
      >
        {{ getStatusLabel(item.status) }}
      </v-chip>
    </template>
    <template v-slot:item.amount="{ item }">
        {{ item.amount | formatNumber }}
    </template>
    <template v-slot:item.id="{ item }">
        {{ getRenewalNumber(item.id) }}
    </template>
    <template slot="body.append">
      <tr>
          <th>TOTAL</th>
          <th></th>
          <th></th>
          <th></th>
          <th>{{ total() | formatNumber }} HTG</th>
          <th></th>
          <th></th>
          <th></th>
      </tr>
  </template>
  </v-data-table>
</v-card>

</v-card>
</v-container>
</div>
</v-app>
</template>

<script>
import BreadcrumbComponent from '../components/BreadcrumbComponent.vue'
import PreloaderComponent from '../components/PreloaderComponent.vue'
import Datepicker from 'vuejs-datepicker';
import moment from 'moment'

export default {
  name: 'SettingsView',
  methods: {
    load: function(){
      var self = this;
      var from = moment(this.state.from).format('YYYY-MM-DD')
      var to = moment(this.state.to).format('YYYY-MM-DD')
      this.$axios.get('/renewals?include=device,business&orderdesc=renewal_date&from='+from+'&to='+to)
        .then(function (response) {
          self.rows = response.data
          self.enable = true          
        })
    },
    changedate(){
      var from = moment(this.state.from).format('YYYY-MM-DD')
      var to = moment(this.state.to).format('YYYY-MM-DD')
      var self = this;
      this.enable = false;
      this.$axios.get('/renewals?include=device,business&orderdesc=renewal_date&from='+from+'&to='+to)
        .then(function (response) {
          self.rows = response.data
          self.enable = true          
        })
    },
    getStatusColor: function(status){
        var color = "class_red"
        if(status){
          color = "class_green"
        }
        return color
      },
    getStatusLabel: function(status){
      var label = "Bloqué"
      if(status){
        label = "Actif"
      }
      return label
    },
    getAmount: function(amount){
        return amount+" HTG"
    },
    getRenewalNumber: function(id){
      var renewal_number = 10000 + parseInt(id)
      return renewal_number
    },
    total() {
      var total = 0
      for (var i = this.rows.length - 1; i >= 0; i--) {
        if(this.rows[i]['status']){
          
          total = total + parseInt(this.rows[i]['amount'])
        }
      }
      return total
    }
  },
  mounted(){
    this.load()
  },
  data: () => ({
      search: '',
      state: {
        from: new Date(),
        to: new Date(),
      },
      dialog:false,
      loading: false,
      status: [{name: "Actif", id: true}, {name: 'Annulé', id: false}],
      index: -1,
      items: [
        {
          text: 'Renouvellements',
          disabled: false,
          href: '/renewals',
        }
      ],
      fluid: true,
      enable: false,
      headers: [
          {
            text: '#',
            align: 'start',
            sortable: false,
            value: 'id',
          },
          { text: 'COMPTE', value: 'business.name' },
          { text: 'APPAREIL', value: 'device.name' },
          { text: 'CREE LE', value: 'renewal_date' },
          { text: 'MONTANT', value: 'amount' },
          { text: 'PROCHAIN RENOUVELLEMENT', value: 'paid_until' },
        { text: 'STATUT', value: 'status' },
          { text: 'MEMO', value: 'memo' },
        ],
        rows: [],
    }),
  components: { BreadcrumbComponent, PreloaderComponent, Datepicker },
}
</script>

<style type="text/css">
  .text-right{
    text-align:right;
  }

  .dateinput{
    border:1px solid #ddd;
    padding:5px;
  }
  .theme--dark.v-btn.v-btn--has-bg{
    background-color:#F68D3A !important;
  }

  .settingscontainer{
    margin-top:10px!important;
  }
  .v-application a{
    color:black!important;
  }
  .tablesearch .v-input__control{
    border: 1px solid #ddd;
    padding: 5px 10px;
  }
  th.text-start, td.text-start{
    text-align:center!important;
  }
  th.text-start:first-child, td.text-start:first-child{
    text-align:left!important;
  }
  th.text-start:last-child, td.text-start:last-child{
    text-align:right!important;
  }
  .class_green{
   background:#4caf50 !important;
   color:white!important;
  }
  .class_red, .confirm_delete_button{
   background:#ff5252 !important;
   color:white!important;
  }
  .uppercase{
    text-transform: uppercase;
  }

  .text-center{
    text-align:center;
  }

  .tablesearch .v-input__slot:before{
    display:none;
  }

  .filterrow{
    height:60px;
  }

  .tablesearch .v-input__control{
    border: 1px solid #ddd;
    padding: 5px 10px;
  }
  .edit_button{
    font-size: 16px;
    padding: 5px;
    background: #1976d2 !important;
    color: white!important;
    border-radius: 4px;
  }
  .delete_button{
    background:#ff5252 !important;
    color: white!important;
    border-radius: 4px;
    font-size: 16px;
    padding: 5px;
  }
  .v-application .justify-center{
    justify-content: right!important;
  }
  .v-application .v-btn{
    width:70px!important;
    margin-right:22px;
    margin-top:-8px;
  }
  .v-dialog .v-card__title{
    background:#f3f3f3;
    border-bottom:1px solid #ddd;
  }
  .v-btn.close_modal_button{
    background-color:#1976d2!important;
    color:white!important;
  }
  .v-btn.confirm_modal_button{
    background-color:#4caf50!important;
    color:white!important;
  }

  .v-dialog .v-card__actions{
    padding-bottom: 15px!important;
    padding-top: 20px!important;
    background: #f3f3f3;
    border-top: 1px solid #ddd;
  }

  .color-red{
    color:red!important;
  }
  .has_errors{
    padding-top:0px;
    margin-top:0px;
  }

  .v-card__actions .v-progress-circular{
    margin-top:1px !important;
  }
    .v-card__actions .v-progress-circular circle{
      color:white!important;
    }
</style>